import $ from "jquery";
import Swiper from 'swiper/bundle';

$(document).ready(function(){
  const swiper01 = new Swiper("#swiper01", {
    loop: false,
    centeredSlides: false,
    slidesPerView: 1.4,
    breakpoints: {
      769: {
        slidesPerView: 3.3,
      }
    },
    spaceBetween: 0,
    speed: 600, 
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev"
    // },
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
      draggable: true,
      dragClass: 'swiper-scrollbar-drag',
      dragSize: 80,
      snapOnRelease: false
    }
  });
});

$(document).ready(function(){
  const swiper02 = new Swiper("#swiper02", {
    loop: false,
    centeredSlides: false,
    slidesPerView: 1.2,
    breakpoints: {
      769: {
        slidesPerView: 1.3,
      }
    },
    spaceBetween: 0,
    speed: 600, 
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev"
    // },
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
      draggable: true,
      dragClass: 'swiper-scrollbar-drag',
      dragSize: 80,
      snapOnRelease: false
    }
  });
});